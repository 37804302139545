<template>
  <div>

    <vue-element-loading
      style="min-height: 500px;"
      :active="carregamentoApi"
      text="Carregando Playlists"
    >
      <img
        src="@/assets/images/logo/logo-loading.gif"
        width="40px"
      >
    </vue-element-loading>

    <hit-comprador-playlist-add
      :add-playlist-sidebar-ativo.sync="addPlaylistSidebarAtivo"
      @recarregarTabela="carregarLista()"
    />

    <b-card no-body class="mb-0">

          <div class="ml-2 my-2">

            <b-row>
              <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                <label>Exibir</label>
                <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
                  :clearable="false" class="per-page-selector d-inline-block mx-50" @input="alterarPorPagina"/>
              </b-col>
      
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                   
                  <b-button class="mr-2" variant="primary" @click="addPlaylistSidebarAtivo = true">
                    <span class="text-nowrap">Criar Playlist</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>

        </div>

          <b-table hover :items="playlists" :fields="fields" :per-page="perPage" :current-page="currentPage">

            <template #cell(id)="data">
              <b-media vertical-align="center">
                <b-link
                  :to="{ name: 'hits-playlist-info', params: { id: data.item.id } }"
                  class="font-weight-bold d-block text-nowrap"
                >
                  #{{ data.item.id }}
                </b-link>
              </b-media>
            </template>

            <template #cell(nomePlaylist)="row">
              {{ row.item.nomePlaylist }}
            </template>

            <template #cell(remover)="data">
              <b-button variant="link" size="sm" class="float-right" @click="removerPlaylist(data.item.id)">
                <feather-icon v-if="!respostasApi[data.item.id]" icon="Trash2Icon" size="18" class="text-danger"/>
                <span v-if="respostasApi[data.item.id]" class="ml-1 position-absolute" style="right: 50%; top: 0%; transform: translate(-50%, -0%);">
                  <vue-element-loading spinner="spinner" color="#EA5455" :active="respostasApi[data.item.id]" width="20px" height="20px"/>
                </span>
              </b-button>
            </template>
          </b-table>

          <div class="mx-2 my-2">
            <b-row>
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                </b-col>
  
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                  <b-pagination v-model="currentPage" :total-rows="totalLista" :per-page="perPage" first-number last-number
                  class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @input="mudarPagina">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18"/>
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18"/>
                  </template>
                  </b-pagination>  
                </b-col>
            </b-row>
          </div>
        </b-card>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

// CARREGANDO DE PÁGINA PARA API
import VueElementLoading from 'vue-element-loading'

import useJwt from '@/auth/jwt/useJwt'

import HitCompradorPlaylistAdd from './HitCompradorPlaylistAdd.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    VueElementLoading,

    HitCompradorPlaylistAdd,
  },
  data() {
    return {
      addPlaylistSidebarAtivo: false,
      playlists: [],
      fields: [
        { key: 'id', label: 'ID' },
        { key: 'nomePlaylist', label: 'Nome da Playlist' },
        { key: 'remover', label: '' }
      ],
      perPage: 10,
      currentPage: 1,
      totalLista: 0,
      perPageOptions: [10, 25, 50, 100],
      searchQuery: '',
      sortBy: 'id',
      isSortDirDesc: true,
      refUserListTable: null,
      carregamentoApi: false,
      respostaApi: false,
      respostasApi: {},
    }
  },
  created() {
    this.usuarioCompradorPlaylist();
  },
  methods: {
    usuarioCompradorPlaylist() {
      this.carregamentoApi = true
      const usuarioId = JSON.parse(localStorage.getItem('usuarioData'))?.sub
      const paginaAtual = this.currentPage - 1;
      useJwt.playlistListar({
        usuarioId:  usuarioId,
        page: paginaAtual,
        size: this.perPage,
        sort: "ASC"
      })
      .then(response => {
        this.playlists = response.data.content
        this.totalLista = response.data.totalElements
      })
      .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.carregamentoApi = false
        })
    },
    mudarPagina() {
        this.carregarLista()
      },

      alterarPorPagina() {
        this.carregarLista()
      },

      removerPlaylist(playlistId) {
        this.$swal({
          title: 'Tem certeza?',
          text: 'Você está prestes a remover esta Playlist. Esta ação não pode ser revertida.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim, remover',
          cancelButtonText: 'Cancelar',
          customClass: {
            confirmButton: 'btn btn-danger',
            cancelButton: 'btn btn-outline-secondary ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.$set(this.respostasApi, playlistId, true)
            useJwt.playlistRemover({
              playlistId: playlistId,
            })
            .then(response => {
              this.$swal({
                title: 'Playlist Removida!',
                text: 'A playlist foi removida.',
                icon: 'success',
                confirmButtonText: 'OK',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              }).then(() => {
                window.location.reload()
              });
            })
            .catch(error => {
              this.$swal({
                title: 'Erro!',
                text: error.response.data.message || 'Ocorreu um erro ao remover a playlist.',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }).finally(() => {
              this.$set(this.respostasApi, playlistId, false)
              })
            }
        })
    },
  },
}
</script>

<style lang="scss">
/* Adicione estilos CSS opcionais aqui */
</style>
