<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="addPlaylistSidebarAtivo"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:add-playlist-sidebar-ativo', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Criar Playlist
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{invalid}"
        ref="abilityForm"
      >
        <!-- Form -->
        <b-form
          ref="abilityForms"
          class="p-2"
          @submit.prevent="playlistCadastrar"
        >

        <b-form-group label="Nome da Playlist" label-for="nomePlaylist">
          <validation-provider #default="{ errors }" name="nomePlaylist" rules="required">
            <b-form-input id="nomePlaylist" v-model="nomePlaylist" :state="errors.length > 0 ? false:null"
              placeholder="Digite o nome da Playlist"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        
          <b-form-group label="Descrição da Playlist" label-for="descricaoPlaylist">
            <validation-provider #default="{ errors }" name="descricaoPlaylist">
              <b-form-textarea id="descricaoPlaylist" v-model="descricaoPlaylist" :state="errors.length > 0 ? false : null" 
                  placeholder="Digite a descrição da Playlist"
                  rows="5"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="invalid || respostaApi"
            >
              Adicionar
              <b-spinner
                v-if="respostaApi"
                small
                label="Carregando"
              />
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BFormInvalidFeedback, BButton, BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import { localize } from "vee-validate";

localize({
  en: {
    messages: {
    },
    fields: {
      nomePlaylist: {
        required: 'O nome é obrigatório.',
      },
    }
  }
});

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BSpinner,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'addPlaylistSidebarAtivo',
    event: 'update:add-playlist-sidebar-ativo',
  },
  props: {
    addPlaylistSidebarAtivo: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      nomePlaylist: '',
      descricaoPlaylist: '',
      usuarioId: null,
      respostaApi: false,
    }
  },
  methods: {  

  playlistCadastrar() {
      this.$refs.abilityForm.validate().then(success => {
          if (success) {
          this.respostaApi = true
          this.usuarioId = JSON.parse(localStorage.getItem('usuarioData'))?.sub

          useJwt.playlistCadastrar({
              usuarioId: this.usuarioId,
              nome: this.nomePlaylist,
              descricao: this.descricaoPlaylist
          })
              .then(response => {
              this.$swal({
                  title: 'Playlist criada!',
                  text: 'A playlist foi criada com sucesso.',
                  icon: 'success',
                  customClass: {
                  confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,

              }).then(result => {
                  if (result.isConfirmed) {
                  this.$refs.abilityForms.reset()
                  this.$emit('update:add-playlist-sidebar-ativo', false)
                  this.$emit('recarregarTabela')
                  } else {
                  this.$refs.abilityForms.reset()
                  this.$emit('update:add-playlist-sidebar-ativo', false)
                  this.$emit('recarregarTabela')
                }
                window.location.reload()
              })
              })
              .catch(error => {
              this.$swal({
                  title: 'Erro!',
                  text: error.response.data.message,
                  icon: 'error',
                  customClass: {
                  confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
              })
              })
              .finally(() => {
              this.respostaApi = false
              })
          }
      })
    }  
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
